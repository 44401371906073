/** @jsxImportSource @emotion/react */
import { CircularProgress, Container, Grid, Hidden, Stack, Typography } from "@mui/material";
import cookie from "js-cookie";
import moment from "moment";
import nextCookie from "next-cookies";
import React, { useCallback, useEffect, useState } from "react";

import "@animxyz/core";
import { XyzTransition } from "@animxyz/react";
import { useAtom, useSetAtom } from "jotai";
import dynamic from "next/dynamic";
import { API } from "../../api";
import SharpButton from "../../components/buttons/SharpButton";
import EventCard, { type IEventCard } from "../../components/eventCard";
import LayoutFrontend from "../../components/layouts/layoutFrontend";
import useBetterMediaQuery from "../../hooks/useBetterMediaQuery";
import { Context, buildApiWithQueryParams, fasterFetch } from "../../utils";
import { choicesPrice } from "../../utils/select-options";

import { atomDialogVisible, atomFilterDate, atomFilterPrice } from "./stores";

import { NextSeo } from "next-seo";
const DashboardChannelPicker = dynamic(import("../../components/DashboardChannelPicker/DashboardChannelPicker"), {
	ssr: false,
});
const DashboardBanner = dynamic(import("../../components/DashboardBanner"), { ssr: true });
// import DashboardBanner from "../../components/DashboardBanner";
const FlatSelect = dynamic(import("../../components/selects/FlatSelect"), { ssr: true });
const NowrDatePicker = dynamic(import("../../components/selects/NowrDatePicker"), { ssr: true });
const SearchPlaceAutocomplete = dynamic(import("../../components/inputs/SearchPlaceAutocomplete"), { ssr: true });
const ShareDialog = dynamic(import("../../components/dialogs/ShareDialog"), { ssr: false });
const HomeFilter = dynamic(import("./components/HomeFilter"), { ssr: false });

const size = 12;

const Home = ({ events: firstEvents, token }) => {
	const isMobile = useBetterMediaQuery("md", "down");
	const [filterLocation, setFilterLocation] = useState<{
		lat: number;
		lng: number;
		placeId: string;
	}>();
	const [filterDate, setFilterDate] = useAtom(atomFilterDate);
	const [filterPrice, setFilterPrice] = useAtom(atomFilterPrice);

	const [channelSelected, setChannelSelected] = useState<string[]>([]);
	const [page, setPage] = useState(0);

	const [loading, setLoading] = useState(false);
	const [reachingEnd, setReachingEnd] = useState(false);

	const [events, setEvents] = useState(firstEvents);

	const getEventsWithFilters = useCallback(
		async (forcedPage?: number) => {
			if (forcedPage == 0) setLoading(true);
			const api = token ? API.event.eventsFiltered : API.event.publicEventsFiltered;

			const options: any = {
				page: forcedPage ?? page,
				size,
				paginate: true,
			};

			if (filterLocation) {
				// options.latitude = filterLocation.lat;
				// options.longitude = filterLocation.lng;
				options.sourceId = filterLocation.placeId;
				options.limited = true;
				// options.radius = 30_000;
			}

			if (filterDate) {
				options.dateLb = filterDate.dateLb;
				options.dateUb = filterDate.dateUb;
			}

			if (filterPrice && filterPrice != "any") {
				options.priceType = filterPrice;
			}

			if (channelSelected && channelSelected.length > 0) {
				options["channelsId[]"] = channelSelected.join(",");
			}

			const url = buildApiWithQueryParams(api, options);
			const data = await fasterFetch(url, {
				method: "GET",
				headers: { "X-Auth-Token": token },
			});

			// const onlyDates = data.map(d => moment(d.publichedDate).toLocaleString());

			// console.log(onlyDates);
			setLoading(false);

			return data;
		},
		[channelSelected, filterDate, filterLocation, filterPrice, page, token]
	);

	const setDialogVisible = useSetAtom(atomDialogVisible);
	const [openShareDialog, setOpenShareDialog] = useState(false);

	const [sharableEvent, setSharableEvent] = useState<IEventCard>();

	const filterEvents = async () => {
		setPage(0);
		setReachingEnd(false);
		const data = await getEventsWithFilters(0);
		setEvents(data || []);
	};

	const loadMore = async () => {
		setLoading(true);
		setPage(page + 1);
	};

	useEffect(() => {
		filterEvents();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterLocation, filterDate, filterPrice, channelSelected]);

	useEffect(() => {
		if (page != 0) {
			getEventsWithFilters().then((data) => {
				if (!data || data.length == 0) setReachingEnd(true);
				setEvents([...events, ...data]);
				setLoading(false);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	return (
		<React.Fragment>
			<ShareDialog open={openShareDialog} event={sharableEvent} onClose={() => setOpenShareDialog(false)} />

			<HomeFilter />

			<Container maxWidth="lg" style={{ padding: isMobile ? "10px 24px" : "60px 24px" }}>
				<Hidden mdDown implementation="css">
					<DashboardBanner />
				</Hidden>

				<Stack css={{ marginTop: isMobile ? 0 : 40 }}>
					<XyzTransition appear xyz="fade-2 left-2">
						<div>
							<Typography
								variant={isMobile ? "h6" : "h4"}
								fontWeight={"bold"}
								marginBottom={6}
								children="Scopri tutti gli eventi NOWR"
							/>
						</div>
					</XyzTransition>
					<XyzTransition appear xyz={"fade up stagger-1"}>
						<Grid container spacing={4}>
							<Grid className="xyz-nested" item xs={12} sm={6} md={4} css={{ position: "relative" }}>
								<SearchPlaceAutocomplete
									label="Dove"
									placeholder="Inserisci città"
									onCancel={() => setFilterLocation(undefined)}
									onGeocoded={(place) => {
										setFilterLocation({ placeId: place.place_id, ...place.coordinates });
									}}
								/>
								<div
									onClick={() => setDialogVisible(true)}
									css={{
										zIndex: 100,
										position: "absolute",
										bottom: 0,
										right: 0,
										width: 59,
										height: 59,
										textAlign: "center",
										background: "#ffffff",
										// backgroundColor: "red",
										// boxShadow: "-1px 0px 4px 0px rgba(0, 0, 0, 0.1)",
										borderLeft: "1px solid rgba(0, 0, 0, .06)",
										cursor: "pointer",
										"@media screen and (min-width: 960px)": {
											display: "none",
										},
									}}
								>
									<img alt="" crossOrigin="" src="/icons/add-black.svg" css={{ marginTop: 16 }} />
								</div>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								md={4}
								className="xyz-nested"
								css={{
									"@media screen and (max-width: 959px)": { display: "none" },
								}}
							>
								<NowrDatePicker
									label="Quando"
									onChange={(newDate) => {
										let dateLb, dateUb;
										switch (newDate.value) {
											case "now":
												dateLb = Math.floor(Date.now() / 1000);
												dateUb = dateLb;
												break;
											case "today":
												dateLb = moment().locale("it").startOf("day").unix();
												dateUb = moment().locale("it").endOf("day").unix();
												break;
											case "custom":
												dateLb = moment(newDate.range?.startDate).locale("it").startOf("day").unix();
												dateUb = moment(newDate.range?.endDate).locale("it").endOf("day").unix();
												console.log(newDate);
												break;
											case "any":
											default:
												//do nothing
												break;
										}
										if (dateLb && dateUb) setFilterDate({ dateLb, dateUb });
										else setFilterDate(undefined);
										console.log(dateLb, dateUb);
									}}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								md={4}
								className="xyz-nested"
								css={{
									"@media screen and (max-width: 959px)": { display: "none" },
								}}
							>
								<FlatSelect
									value={filterPrice}
									choices={choicesPrice}
									label="Prezzo"
									onChange={(choice) => setFilterPrice(choice)}
								/>
							</Grid>
						</Grid>
					</XyzTransition>
					<Grid container marginTop={4}>
						<DashboardChannelPicker value={channelSelected?.[0]} onChange={(selected) => setChannelSelected([selected])} />
					</Grid>

					<Grid
						sx={{ marginTop: 0 }}
						container
						spacing={6}
						// justifyContent="center"
						// component={XyzTransitionGroup}
						// appear
						// xyz={`fade${canAnimate ? " stagger" : ""} bottom`}
					>
						{(events || []).map((event) => (
							<Grid key={event.id} item xs={12} sm={6} lg={4}>
								<EventCard
									{...event}
									token={token}
									shareOnClick={() => {
										setOpenShareDialog(true);
										setSharableEvent(event);
									}}
								/>
							</Grid>
						))}
						{events && events.length == 0 && (
							<Grid container justifyContent="center">
								<div>Nessun evento disponibile</div>
							</Grid>
						)}
						<Grid container justifyContent="center" item xs={12}>
							<SharpButton
								style={{ width: "100%", maxWidth: 380 }}
								variant={"bordered"}
								onClick={loadMore}
								disabled={loading || reachingEnd}
							>
								{loading ? <CircularProgress color="secondary" /> : reachingEnd ? "Non ci sono altri eventi" : "Vedi altro"}
							</SharpButton>
						</Grid>
					</Grid>
				</Stack>
			</Container>
		</React.Fragment>
	);
};

const HomeWrapperLayout = ({ token, events }: { token: string; events: any[] }) => {
	return (
		<LayoutFrontend token={token}>
			<NextSeo />
			<Home token={token} events={events} />
		</LayoutFrontend>
	);
};

HomeWrapperLayout.getInitialProps = async (ctx: Context) => {
	const { nowr_token } = (ctx && nextCookie(ctx)) || {
		nowr_token: cookie.get("nowr_token"),
	};

	const api = nowr_token ? API.event.eventsFiltered : API.event.publicEventsFiltered;

	const url = buildApiWithQueryParams(api, {
		page: 0,
		size,
		paginate: true,
	});
	const events = await fasterFetch(url, {
		method: "GET",
		headers: { "X-Auth-Token": nowr_token ?? "" },
	});

	return { events, token: nowr_token };
};

export default HomeWrapperLayout;
