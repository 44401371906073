export const choicesPrice = [
  { name: "Qualsiasi prezzo", value: "any" },
  { name: "Gratis", value: "GRATIS" },
  { name: "A pagamento", value: "PAYING" },
];

export const choicesNation = [
  { value: "AF", name: "Afghanistan" },
  { value: "AX", name: "Isole Aland" },
  { value: "AL", name: "Albania" },
  { value: "DZ", name: "Algeria" },
  { value: "AS", name: "Samoa Americane" },
  { value: "AD", name: "Andorra" },
  { value: "AO", name: "Angola" },
  { value: "AI", name: "Anguilla" },
  { value: "AQ", name: "Antartide" },
  { value: "AG", name: "Antigua E Barbuda" },
  { value: "AR", name: "Argentina" },
  { value: "AM", name: "Armenia" },
  { value: "AW", name: "Aruba" },
  { value: "AU", name: "Australia" },
  { value: "AT", name: "Austria" },
  { value: "AZ", name: "Azerbaijan" },
  { value: "BS", name: "Bahamas" },
  { value: "BH", name: "Bahrain" },
  { value: "BD", name: "Bangladesh" },
  { value: "BB", name: "Barbados" },
  { value: "BY", name: "Bielorussia" },
  { value: "BE", name: "Belgio" },
  { value: "BZ", name: "Belize" },
  { value: "BJ", name: "Benin" },
  { value: "BM", name: "Bermuda" },
  { value: "BT", name: "Bhutan" },
  { value: "BO", name: "Bolivia" },
  { value: "BA", name: "Bosnia Erzegovina" },
  { value: "BW", name: "Botswana" },
  { value: "BV", name: "Isola Di Bouvet" },
  { value: "BR", name: "Brasile" },
  { value: "IO", name: "Territorio Britannico Dell'Oceano Indiano" },
  { value: "BN", name: "Bruneidarussalam" },
  { value: "BG", name: "Bulgaria" },
  { value: "BF", name: "Burkinafaso" },
  { value: "BI", name: "Burundi" },
  { value: "KH", name: "Cambogia" },
  { value: "CM", name: "Camerun" },
  { value: "CA", name: "Canada" },
  { value: "CV", name: "Capeverde" },
  { value: "KY", name: "Isole Cayman" },
  { value: "CF", name: "Repubblica Centrafricana" },
  { value: "TD", name: "Chad" },
  { value: "CL", name: "Chile" },
  { value: "CN", name: "Cina" },
  { value: "CX", name: "Isola Di Natale" },
  { value: "CC", name: "Isole Di Cocos (Keeling)" },
  { value: "CO", name: "Colombia" },
  { value: "KM", name: "Comoros" },
  { value: "CG", name: "Congo" },
  { value: "CD", name: "Congo, Repubblica Democratica" },
  { value: "CK", name: "Isole Cook" },
  { value: "CR", name: "Costarica" },
  { value: "CI", name: "Costa D'Avorio" },
  { value: "HR", name: "Croazia" },
  { value: "CU", name: "Cuba" },
  { value: "CY", name: "Cipro" },
  { value: "CZ", name: "Repubblica Ceca" },
  { value: "DK", name: "Danimarca" },
  { value: "DJ", name: "Djibuti" },
  { value: "DM", name: "Dominica" },
  { value: "DO", name: "Repubblica Dominicana" },
  { value: "EC", name: "Ecuador" },
  { value: "EG", name: "Egitto" },
  { value: "SV", name: "Il Salvatore" },
  { value: "GQ", name: "Guinea Equatoriale" },
  { value: "ER", name: "Eritrea" },
  { value: "EE", name: "Estonia" },
  { value: "ET", name: "Etiopia" },
  { value: "FK", name: "Isole Falkland (Malvinas)" },
  { value: "FO", name: "Isole Faroe" },
  { value: "FJ", name: "Fiji" },
  { value: "FI", name: "Finlandia" },
  { value: "FR", name: "Francia" },
  { value: "GF", name: "Guiana Francese" },
  { value: "PF", name: "Polinesia Francese" },
  { value: "TF", name: "Territori Della Francia Del Sud" },
  { value: "GA", name: "Gabon" },
  { value: "GM", name: "Gambia" },
  { value: "GE", name: "Georgia" },
  { value: "DE", name: "Germania" },
  { value: "GH", name: "Ghana" },
  { value: "GI", name: "Gibilterra" },
  { value: "GR", name: "Grecia" },
  { value: "GL", name: "Groenlandia" },
  { value: "GD", name: "Grenada" },
  { value: "GP", name: "Guadalupa" },
  { value: "GU", name: "Guam" },
  { value: "GT", name: "Guatemala" },
  { value: "GG", name: "Guernsey" },
  { value: "GN", name: "Guinea" },
  { value: "GW", name: "Guinea-Bissau" },
  { value: "GY", name: "Guyana" },
  { value: "HT", name: "Haiti" },
  { value: "HM", name: "Heard Island & Mcdonald Islands" },
  { value: "VA", name: "Santa Sede (Stato Della Città Del Vaticano)" },
  { value: "HN", name: "Honduras" },
  { value: "HK", name: "Hongkong" },
  { value: "HU", name: "Ungheria" },
  { value: "IS", name: "Islanda" },
  { value: "IN", name: "India" },
  { value: "ID", name: "Indonesia" },
  { value: "IR", name: "Iran (Repubblica Islamica Del" },
  { value: "IQ", name: "Iraq" },
  { value: "IE", name: "Irlanda" },
  { value: "IM", name: "Isola Di Man" },
  { value: "IL", name: "Israele" },
  { value: "IT", name: "Italia" },
  { value: "JM", name: "Giamaica" },
  { value: "JP", name: "Giappone" },
  { value: "JE", name: "Maglia" },
  { value: "JO", name: "Giordania" },
  { value: "KZ", name: "Kazakistan" },
  { value: "KE", name: "Kenya" },
  { value: "KI", name: "Kiribati" },
  { value: "KR", name: "Corea" },
  { value: "KP", name: "Corea Del Nord" },
  { value: "KW", name: "Kuwait" },
  { value: "KG", name: "Kirghizistan" },
  { value: "LA", name: "Repubblica Democratica Popolare Di Lao" },
  { value: "LV", name: "Lettonia" },
  { value: "LB", name: "Libano" },
  { value: "LS", name: "Lesotho" },
  { value: "LR", name: "Liberia" },
  { value: "LY", name: "Jamahiriya Arabica Libica" },
  { value: "LI", name: "Liechtenstein" },
  { value: "LT", name: "Lituania" },
  { value: "LU", name: "Lussemburgo" },
  { value: "MO", name: "Macao" },
  { value: "MK", name: "Macedonia" },
  { value: "MG", name: "Madagascar" },
  { value: "MW", name: "Malawi" },
  { value: "MY", name: "Malaysia" },
  { value: "MV", name: "Maldive" },
  { value: "ML", name: "Mali" },
  { value: "MT", name: "Malta" },
  { value: "MH", name: "Isole Marshall" },
  { value: "MQ", name: "Martinica" },
  { value: "MR", name: "Mauritania" },
  { value: "MU", name: "Mauritius" },
  { value: "YT", name: "Perduto" },
  { value: "MX", name: "Messico" },
  { value: "FM", name: "Micronesia, Stati Federati Di" },
  { value: "MD", name: "Moldova" },
  { value: "MC", name: "Monaco" },
  { value: "MN", name: "Mongolia" },
  { value: "ME", name: "Montenegro" },
  { value: "MS", name: "Montserrat" },
  { value: "MA", name: "Marocco" },
  { value: "MZ", name: "Mozambico" },
  { value: "MM", name: "Myanmar" },
  { value: "NA", name: "Namibia" },
  { value: "NR", name: "Nauru" },
  { value: "NP", name: "Nepal" },
  { value: "NL", name: "Olanda" },
  { value: "AN", name: "Antille Olandesi" },
  { value: "NC", name: "Nuova Caledonia" },
  { value: "NZ", name: "Nuova Zelanda" },
  { value: "NI", name: "Nicaragua" },
  { value: "NE", name: "Niger" },
  { value: "NG", name: "Nigeria" },
  { value: "NU", name: "Niue" },
  { value: "NF", name: "Isola Di Norfolk" },
  { value: "MP", name: "Isole Marianne Settentrionali" },
  { value: "NO", name: "Norvegia" },
  { value: "OM", name: "Oman" },
  { value: "PK", name: "Pakistan" },
  { value: "PW", name: "Palau" },
  { value: "PS", name: "Territorio Palestinese, Occupato" },
  { value: "PA", name: "Panama" },
  { value: "PG", name: "Papua Nuova Guinea" },
  { value: "PY", name: "Paraguay" },
  { value: "PE", name: "Perù" },
  { value: "PH", name: "Filippine" },
  { value: "PN", name: "Pitcairn" },
  { value: "PL", name: "Polonia" },
  { value: "PT", name: "Portogallo" },
  { value: "PR", name: "Puerto Rico" },
  { value: "QA", name: "Qatar" },
  { value: "RE", name: "Riunione" },
  { value: "RO", name: "Romania" },
  { value: "RU", name: "Federazione Russa" },
  { value: "RW", name: "Ruanda" },
  { value: "BL", name: "Saint Barthelemy" },
  { value: "SH", name: "Saint Helena" },
  { value: "KN", name: "Saint Kitts E Nevis" },
  { value: "LC", name: "Santa Lucia" },
  { value: "MF", name: "Saint-Martin" },
  { value: "PM", name: "Saint Pierre E Miquelon" },
  { value: "VC", name: "Saint Vincent E Grenadine" },
  { value: "WS", name: "Samoa" },
  { value: "SM", name: "Sanmarino" },
  { value: "ST", name: "Sao Tome E Principe" },
  { value: "SA", name: "Arabia Saudita" },
  { value: "SN", name: "Senegal" },
  { value: "RS", name: "Serbia" },
  { value: "SC", name: "Seychelles" },
  { value: "SL", name: "Sierra Leone" },
  { value: "SG", name: "Singapore" },
  { value: "SK", name: "Slovacchia" },
  { value: "SI", name: "Slovenia" },
  { value: "SB", name: "Isole Salomone" },
  { value: "SO", name: "Somalia" },
  { value: "ZA", name: "Sud Africa" },
  { value: "GS", name: "South Georgia E Sandwich Isl" },
  { value: "ES", name: "Spagna" },
  { value: "LK", name: "Srilanka" },
  { value: "SD", name: "Sudan" },
  { value: "SR", name: "Suriname" },
  { value: "SJ", name: "Svalbard E Jan Mayen" },
  { value: "SZ", name: "Swaziland" },
  { value: "SE", name: "Svezia" },
  { value: "CH", name: "Svizzera" },
  { value: "SY", name: "Repubblica Araba Siriana" },
  { value: "TW", name: "Taiwan" },
  { value: "TJ", name: "Tagikistan" },
  { value: "TZ", name: "Tanzania" },
  { value: "TH", name: "Tailandia" },
  { value: "TL", name: "Timor-Leste" },
  { value: "TG", name: "Andare" },
  { value: "TK", name: "Tokelau" },
  { value: "TO", name: "Tonga" },
  { value: "TT", name: "Trinidad E Tobago" },
  { value: "TN", name: "Tunisia" },
  { value: "TR", name: "Tacchina" },
  { value: "TM", name: "Turkmenistan" },
  { value: "TC", name: "Isole Turks E Caicos" },
  { value: "TV", name: "Tuvalu" },
  { value: "UG", name: "Uganda" },
  { value: "UA", name: "Ucraina" },
  { value: "AE", name: "Emirati Arabi Uniti" },
  { value: "GB", name: "Regno Unito" },
  { value: "US", name: "Stati Uniti" },
  { value: "UM", name: "Isole Periferiche Degli Stati Uniti" },
  { value: "UY", name: "Uruguay" },
  { value: "UZ", name: "Uzbekistan" },
  { value: "VU", name: "Vanuatu" },
  { value: "VE", name: "Venezuela" },
  { value: "VN", name: "Vietnam" },
  { value: "VG", name: "Isole Vergini, Britanniche" },
  { value: "VI", name: "Isole Vergini, Us" },
  { value: "WF", name: "Wallis E Futuna" },
  { value: "EH", name: "Sahara Occidentale" },
  { value: "YE", name: "Yemen" },
  { value: "ZM", name: "Zambia" },
  { value: "ZW", name: "Zimbabwe" },
];
